import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "my-4 space-x-2" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "grid grid-cols-10 2xl:gap-4 gap-2" }
const _hoisted_7 = { class: "col-span-4" }
const _hoisted_8 = { class: "box relative py-3" }
const _hoisted_9 = { class: "col-span-6" }
const _hoisted_10 = {
  key: 0,
  class: "box py-3 space-y-3 h-full"
}
const _hoisted_11 = { class: "flex items-center space-x-3" }
const _hoisted_12 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/scope-2-renewables-performance" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Scope 2 Renewable Performance ")
          ])),
          _: 1
        })
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "input-PPA-and-RECs" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Input PPA ")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values($setup.HASH_SCOPE_2), (value) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "inline-block",
          key: value
        }, [
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass(['tab-box', $setup.route.hash === `#${value}` && 'active']),
            onClick: _withModifiers(($event: any) => ($setup.handleTabClick(value)), ["prevent"])
          }, _toDisplayString($setup.computeTabName($setup.TAB_SCOPE_2, value)), 11, _hoisted_5)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          ($setup.route.hash === `#${$setup.HASH_SCOPE_2.PPA}`)
            ? (_openBlock(), _createBlock($setup["ThePPAForm"], {
                key: 0,
                onResetFormPPAOnClickRow: $setup.resetFormOnClickRowPPA
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        ($setup.route.hash === `#${$setup.HASH_SCOPE_2.PPA}`)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _cache[3] || (_cache[3] = _createElementVNode("h1", null, "Status of PPA Processing", -1)),
              _withDirectives((_openBlock(), _createBlock(_component_el_table, {
                class: "w-full max-h-full table-custom",
                style: { borderRadius: '4px' },
                data: $setup.PPARequestData,
                "cell-class-name": "text-cafe-black",
                "header-row-class-name": "tableHeaderRow-withBg",
                "row-class-name": "tableRow",
                onRowClick: $setup.handleRowClickPPA,
                "element-loading-text": "Loading...",
                "element-loading-background": "rgba(0, 0, 0, 0.6)"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    prop: "FromDate",
                    label: "Time",
                    width: "150"
                  }, {
                    default: _withCtx((scope) => [
                      _createTextVNode(_toDisplayString(`${$setup.formatToUTCStartMonth(
                    scope.row.FromDate
                  )} - ${$setup.formatToUTCMonth(scope.row.ToDate)}`), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    align: "right",
                    prop: "Value",
                    label: "PPA (BUNDLED RECS)",
                    width: "200"
                  }, {
                    default: _withCtx((scope) => [
                      _createTextVNode(_toDisplayString(`${$setup.formatNumber(scope.row.Value)}`), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "Notification",
                    label: "NOTIFICATION"
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "RequestStatusName",
                    label: "STATUS"
                  }, {
                    default: _withCtx((scope) => [
                      _createElementVNode("div", _hoisted_11, [
                        (scope.row.RequestStatusName === 'Approved')
                          ? (_openBlock(), _createElementBlock("p", {
                              key: 0,
                              class: _normalizeClass(_ctx.styles['text-green'])
                            }, _toDisplayString(scope.row.RequestStatusName), 3))
                          : _createCommentVNode("", true),
                        (scope.row.RequestStatusName === 'Rejected')
                          ? (_openBlock(), _createElementBlock("p", {
                              key: 1,
                              class: _normalizeClass(_ctx.styles['text-red'])
                            }, _toDisplayString(scope.row.RequestStatusName), 3))
                          : _createCommentVNode("", true),
                        (scope.row.RequestStatusName === 'Pending')
                          ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(scope.row.RequestStatusName), 1))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["data"])), [
                [_directive_loading, $setup.isLoadingPPARequestData]
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}