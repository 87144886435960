import { defineComponent as _defineComponent } from 'vue'
import AccountModule from "@/store/modules/Account";
import { isCSV } from "@/utils/helpers/checkFileType";
import { computeTabName, useHash } from "@/utils/hooks";
import { HASH_SCOPE_2, TAB_SCOPE_2 } from "@ems/constants";
import ThePPAForm from "@ems/containers/GreenOptions/TheForm/PPA.Form.vue";
import { ElMessage } from "element-plus/lib/components/message";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import PPAModule from "@ems/containers/GreenOptions/TheForm/PPA.module";
import {
  formatToUTCStartMonth,
  formatToUTCMonth,
} from "@/utils/helpers/convertDateTime";
import { formatNumber } from "@/utils/helpers/buildDataFormat";
import isEmpty from "lodash/isEmpty";
import { IScopeTwoPPADetailParameters } from "@/models/ScopeTwoEmissions";

interface RawFile {
  name: string;
  url: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'InputPPA_RECs',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const PPAForm = ref();

const PPARequestData = computed(() => PPAModule.PPARequestDataGetter);

const isLoadingPPARequestData = computed(
  () => PPAModule.isLoadingPPARequestGetter
);
useHash(HASH_SCOPE_2);

const handleTabClick = (tabs: string) => {
  router.replace({ hash: `#${tabs}` });
};
const Authorization = computed(
  () => AccountModule.getUserInfo?.authorizationString + ""
);

const beforeUploadFile = (file: any) => {
  const checkCSV = isCSV(file.name);
  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!checkCSV) {
    ElMessage.error("File must be .csv, .xlsx, .xls format!");
  }
  if (!isLt2M) {
    ElMessage.error("File size can not exceed 2MB!");
  }
  return checkCSV && isLt2M;
};
const handleRowClickPPA = async (row: any) => {
  handleResetPPAForm();
  PPAModule.togglePPAButtonType(true);
  await PPAModule.getPPARequestDetailAction({
    ppaId: row.Id,
  } as IScopeTwoPPADetailParameters);
};
const handleResetPPAForm = () => {
  !isEmpty(PPAForm.value) && PPAForm.value.resetForm({ values: {} });
};
const resetFormOnClickRowPPA = (form: any) => {
  PPAForm.value = form;
};
const fileListPPA = ref<RawFile[]>([
  {
    name: "Document 1.pdf",
    url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
  },
  {
    name: "Document 2.pdf",
    url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
  },
]);

const __returned__ = { route, router, PPAForm, PPARequestData, isLoadingPPARequestData, handleTabClick, Authorization, beforeUploadFile, handleRowClickPPA, handleResetPPAForm, resetFormOnClickRowPPA, fileListPPA, get AccountModule() { return AccountModule }, get isCSV() { return isCSV }, get computeTabName() { return computeTabName }, get useHash() { return useHash }, get HASH_SCOPE_2() { return HASH_SCOPE_2 }, get TAB_SCOPE_2() { return TAB_SCOPE_2 }, ThePPAForm, get ElMessage() { return ElMessage }, computed, ref, get useRoute() { return useRoute }, get useRouter() { return useRouter }, get PPAModule() { return PPAModule }, get formatToUTCStartMonth() { return formatToUTCStartMonth }, get formatToUTCMonth() { return formatToUTCMonth }, get formatNumber() { return formatNumber }, get isEmpty() { return isEmpty }, get IScopeTwoPPADetailParameters() { return IScopeTwoPPADetailParameters } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})