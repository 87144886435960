import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex mb-6 space-x-4" }
const _hoisted_2 = { class: "inputfield inputfield__underline w-1/2" }
const _hoisted_3 = { class: "inputfield inputfield__underline w-1/2" }
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = { class: "flex justify-end mt-4" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["Form"], {
      as: "el-form",
      "label-position": "top",
      "validation-schema": $setup.schema,
      "initial-values": $setup.PPADataFromFacility,
      ref: "selectFormField"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode($setup["Field"], {
              name: "Country",
              ref: "countryField"
            }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield",
                  error: errorMessage,
                  label: "Country",
                  "label-position": "top"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, _mergeProps({ class: "2xl:w-full lg:w-32 xl:w-48 md:w-52" }, field, {
                      "model-value": value,
                      placeholder: "Select",
                      onChange: (value) => $setup.handleChangeCountry(value)
                    }), {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataCountries, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.Value,
                            label: item.Name,
                            value: item.Value
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1040, ["model-value", "onChange"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }, 512)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode($setup["Field"], {
              name: "FacilityId",
              ref: "facilityField"
            }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield",
                  error: errorMessage,
                  label: "Facility",
                  "label-position": "top"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, _mergeProps({ class: "2xl:w-full lg:w-32 xl:w-48 md:w-52" }, field, {
                      "model-value": value,
                      placeholder: "Select",
                      onChange: (value) => $setup.handleChangeFacility(value)
                    }), {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.facilities.data, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.Value,
                            label: item.Name,
                            value: item.Value
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1040, ["model-value", "onChange"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }, 512)
          ])
        ])
      ]),
      _: 1
    }, 8, ["validation-schema", "initial-values"]),
    _createVNode($setup["Form"], {
      as: "el-form",
      onSubmit: $setup.onSubmit,
      "label-width": "",
      ref: "PPAForm",
      "initial-values": $setup.PPADataDetail,
      "validation-schema": $setup.schemaPPA
    }, {
      default: _withCtx(({ setFieldValue, values, resetForm, isSubmitting }) => [
        _createElementVNode("div", {
          class: _normalizeClass(
        $setup.isLoadingPPARequestDetail || isSubmitting ? 'isSubmitting-rounded' : ''
      )
        }, [
          _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "text-left text-lg 2xl:text-xl font-bold mb-2" }, " Please enter the PPA (Bundled RECS) ", -1)),
          _createElementVNode("div", _hoisted_4, [
            _createVNode($setup["Field"], { name: "MonthRange" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield inputfield__underline inline-block mr-12 text-sm range-editor",
                  error: errorMessage
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_date_picker, _mergeProps({
                      class: [$setup.isReadonlyPPAForm && 'disabled-input']
                    }, field, {
                      "model-value": value,
                      type: "monthrange",
                      editable: false,
                      "disabled-date": (time) => time.getTime() > Date.now(),
                      placeholder: "",
                      format: "MM/YYYY",
                      disabled: $setup.isReadonlyPPAForm,
                      "prefix-icon": $setup.customPrefix,
                      "start-placeholder": "Start month",
                      "range-separator": "To:",
                      "end-placeholder": "End month",
                      onChange: (value) => $setup.handleChangeMonthRange(value)
                    }), null, 16, ["class", "model-value", "disabled-date", "disabled", "prefix-icon", "onChange"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", null, [
            _createVNode($setup["Field"], { name: "Value" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield inline withUnit",
                  error: errorMessage,
                  label: "PPA (Bundled RECs)"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({
                      controls: false,
                      class: ['w-56', $setup.isReadonlyPPAForm && 'disabled-input'],
                      placeholder: ""
                    }, field, {
                      "model-value": value,
                      disabled: $setup.isReadonlyPPAForm,
                      onBlur: () => $setup.onBlurValueField(value, setFieldValue, `Value`),
                      onFocus: () => $setup.onFocusValueField(value, setFieldValue, `Value`),
                      onInput: 
                    (val) =>
                      $setup.onChangeValueField(val, setFieldValue, `Value`)
                  
                    }), null, 16, ["class", "model-value", "disabled", "onBlur", "onFocus", "onInput"]),
                    _cache[0] || (_cache[0] = _createElementVNode("p", { class: "2xl:text-lg" }, "kWh/year", -1))
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 2
            }, 1024)
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("hr", { class: "border-gray-400" }, null, -1)),
          _createElementVNode("div", _hoisted_5, [
            (!$setup.isReadonlyPPAForm)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "submit",
                  class: "btn btn--green-primary w-15",
                  disabled: isSubmitting
                }, " Upload ", 8, _hoisted_6))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "btn btn--red-primary w-15",
                  onClick: _withModifiers(() => $setup.handleResetForm(values, resetForm), ["prevent"])
                }, " Reset ", 8, _hoisted_7))
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["initial-values", "validation-schema"])
  ], 64))
}