import { defineComponent as _defineComponent } from 'vue'
import { IUploadPPA } from "@/models/FormData";
import {
  IScopeTwoGridEmissionFactor,
  IScopeTwoParameters,
} from "@/models/ScopeTwoEmissions";
import AccountModule from "@/store/modules/Account";
import MetadataModule from "@/store/modules/Metadata";
import {
  formatNumber,
  removeNumberFormat,
  validateInputNumberType,
} from "@/utils/helpers/buildDataFormat";
import { isCSV } from "@/utils/helpers/checkFileType";
import {
  endDateOfMonth,
  startDateOfMonth,
} from "@/utils/helpers/convertDateTime";
import { useHash } from "@/utils/hooks";
import { HASH_SCOPE_2 } from "@ems/constants";
import PPAModule from "@ems/containers/GreenOptions/TheForm/PPA.module";
import { ElMessage } from "element-plus/lib/components/message";
import { configure, Field, Form } from "vee-validate";
import {
  computed,
  h,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  shallowRef,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { array, object, string } from "yup";
interface RawFile {
  name: string;
  url: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PPA.Form',
  emits: ["resetFormPPAOnClickRow"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emits = __emit;
const facilityField = ref();
const countryField = ref();
const PPAForm = ref();
const selectFormField = ref();

const route = useRoute();
const router = useRouter();

configure({
  validateOnBlur: false, // controls if `blur` events should trigger validation with `handleChange` handler

  validateOnChange: false, // controls if `change` events should trigger validation with `handleChange` handler

  validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler

  validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
});
const schema = object({
  Country: string().required().label("Country"),
  FacilityId: string().required().label("Facility"),
});
const schemaPPA = object({
  MonthRange: array().required().nullable().label("MonthRange"),
  Value: string().required().label("PPA"),
});
const isReadonlyPPAForm = computed(() => PPAModule.isReadonlyFormPPAGetter);

const PPADataDetail = computed(() => PPAModule.PPARequestDataDetailGetter);

const isLoadingPPARequestDetail = computed(
  () => PPAModule.isLoadingPPARequestDetailGetter
);
const PPADataFromFacility = computed(() => PPAModule.PPADataFromFacilityGetter);
useHash(HASH_SCOPE_2);

onMounted(() => {
  const facilitiesGetter = MetadataModule.dataFacilitiesGetter;
  facilities.data = facilitiesGetter.filter(
    (fac) => fac.CountryId == PPADataFromFacility.value.Country
  );
  collectData.data = {
    ...collectData.data,
    FacilityId: PPADataFromFacility.value.FacilityId,
    Country: PPADataFromFacility.value.Country,
  };
  if (collectData.data.Country && collectData.data.FacilityId) {
    request.params = {
      FacilityId: collectData.data.FacilityId,
    } as IScopeTwoParameters;
    PPAModule.getPPARequestAction(request.params);
  }
});
onUnmounted(() => {
  emptyPPARequest();
  PPAModule.setPPADataFromFacility({} as IScopeTwoGridEmissionFactor);
});
const emptyPPARequest = () => {
  PPAModule.emptyPPARequest();
  PPAModule.emptyPPARequestDetail();
};
const Authorization = computed(
  () => AccountModule.getUserInfo?.authorizationString + ""
);
const customPrefix = shallowRef({
  render() {
    return h("div", "From:");
  },
});
const dataCountries = computed(() => MetadataModule.dataCountriesGetter);
const facilities = reactive<any>({ data: [] });

const request = reactive<any>({ params: {} });
const collectData = reactive<any>({
  data: {
    Country: "",
    FacilityId: "",
    FromDate: "",
    ToDate: "",
  },
});
const handleResetForm = (values: any, resetForm: any) => {
  resetForm({
    values: {},
  });
  emits("resetFormPPAOnClickRow", PPAForm.value);
  PPAModule.togglePPAButtonType(false);
};
const beforeUploadFile = (file: any) => {
  const checkCSV = isCSV(file.name);
  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!checkCSV) {
    ElMessage.error("File must be .csv, .xlsx, .xls format!");
  }
  if (!isLt2M) {
    ElMessage.error("File size can not exceed 2MB!");
  }
  return checkCSV && isLt2M;
};

const fileListPPA = ref<RawFile[]>([
  {
    name: "Document 1.pdf",
    url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
  },
  {
    name: "Document 2.pdf",
    url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
  },
]);

const handleChangeCountry = async (value: any) => {
  emptyPPARequest();
  PPAModule.togglePPAButtonType(false);
  collectData.data = {
    ...collectData.data,
    Country: value,
  };
  facilityField.value.reset();
  const facilitiesGetter = MetadataModule.dataFacilitiesGetter;
  facilities.data = facilitiesGetter.filter((fac) => fac.CountryId == value);
};
const handleChangeFacility = async (value: any) => {
  collectData.data = {
    ...collectData.data,
    FacilityId: value,
  };
  if (value) {
    request.params = {
      FacilityId: value,
    } as IScopeTwoParameters;
    await PPAModule.getPPARequestAction(request.params);
  }
};
const onSubmit = async (values: any, actions: any) => {
  const result = await selectFormField.value.validate();
  if (!result.valid) return;
  const requestData = {
    Value: removeNumberFormat(values.Value),
    ...collectData.data,
  } as IUploadPPA;

  // await PPAModule.uploadPPAAction(requestData);
  // await PPAModule.getPPARequestAction(request.params);
  // actions.resetForm();
};
const onBlurValueField = (value: any, setFieldValue: any, field: any) => {
  if (!value || value.length < 1) return;
  const result = formatNumber(Number(value), 0);
  setFieldValue(field, result);
};
const onFocusValueField = (value: any, setFieldValue: any, field: any) => {
  if (!value || value.length < 1) return;
  var result = removeNumberFormat(value);
  setFieldValue(field, result);
};
const onChangeValueField = (value: any, setFieldValue: any, field: any) => {
  const result = validateInputNumberType(parseFloat(value));
  setFieldValue(field, result);
};

const handleChangeMonthRange = (value: any) => {
  if (!value) return;
  collectData.data = {
    ...collectData.data,
    FromDate: startDateOfMonth(value[0]),
    ToDate: endDateOfMonth(value[1]),
  };
};

const __returned__ = { emits, facilityField, countryField, PPAForm, selectFormField, route, router, schema, schemaPPA, isReadonlyPPAForm, PPADataDetail, isLoadingPPARequestDetail, PPADataFromFacility, emptyPPARequest, Authorization, customPrefix, dataCountries, facilities, request, collectData, handleResetForm, beforeUploadFile, fileListPPA, handleChangeCountry, handleChangeFacility, onSubmit, onBlurValueField, onFocusValueField, onChangeValueField, handleChangeMonthRange, get IUploadPPA() { return IUploadPPA }, get IScopeTwoGridEmissionFactor() { return IScopeTwoGridEmissionFactor }, get IScopeTwoParameters() { return IScopeTwoParameters }, get AccountModule() { return AccountModule }, get MetadataModule() { return MetadataModule }, get formatNumber() { return formatNumber }, get removeNumberFormat() { return removeNumberFormat }, get validateInputNumberType() { return validateInputNumberType }, get isCSV() { return isCSV }, get endDateOfMonth() { return endDateOfMonth }, get startDateOfMonth() { return startDateOfMonth }, get useHash() { return useHash }, get HASH_SCOPE_2() { return HASH_SCOPE_2 }, get PPAModule() { return PPAModule }, get ElMessage() { return ElMessage }, get configure() { return configure }, get Field() { return Field }, get Form() { return Form }, computed, h, onMounted, onUnmounted, reactive, ref, shallowRef, get useRoute() { return useRoute }, get useRouter() { return useRouter }, get array() { return array }, get object() { return object }, get string() { return string } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})